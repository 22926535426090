// core version + navigation, pagination modules:
// import Swiper, {Navigation, Pagination, Autoplay, EffectFade} from 'swiper/core';

// configure Swiper to use modules
// Swiper.use([Navigation, Pagination, Autoplay, EffectFade]);

import Swiper from 'swiper'
import {Autoplay, EffectFade, Navigation, Mousewheel, Pagination} from 'swiper/modules'

const swiperBlijmakers = new Swiper('#js-swiper-blijmakers', {
    modules: [Navigation, Pagination, Mousewheel, EffectFade, Autoplay],
    loop: true,
    autoHeight: true,
    pagination: {
        el: '#js-swiper-blijmakers-pagination',
        clickable: true,
    },
    navigation: {
        prevEl: '#js-swiper-blijmakers-button-prev',
        nextEl: '#js-swiper-blijmakers-button-next',
    },
});
const swiperHomeQuote = new Swiper('#js-swiper-quotes', {
    modules: [Navigation, Pagination, Mousewheel, EffectFade, Autoplay],
    loop: true,
    autoHeight: true,
    navigation: {
        prevEl: '#js-swiper-quotes-button-prev',
        nextEl: '#js-swiper-quotes-button-next',
    },
});

const swiperQuotes = document.querySelectorAll('.js-swiper-quotes');
for (let i = 0; i < swiperQuotes.length; ++i) {
    // const swiperQuote = swiperQuotes[i];
    new Swiper(swiperQuotes[i], {
        modules: [Navigation, Pagination, Mousewheel, EffectFade, Autoplay],
        loop: true,
        // autoHeight: true,
        autoplay: {
            delay: 5000,
            disableOnInteraction: false
        },
        // effect: "fade",
        // fadeEffect: {
        //     crossFade: true
        // }
    });
}