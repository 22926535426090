window.obaia = {}
window.obaia.Components = {}
window.obaia.Components.marquee = () => {
    let animationFrameId = null
    let shouldAnimate = false
    const throttleDelay = 33 // Throttle delay (approximately 33 = 30FPS, 16 = 60FPS)

    return {
        posX: 0,
        marqueeSpeed: 200, // Speed of the marquee (pixels per second)
        marqueeElement: null,
        marqueeWidth: 0,
        contentWidth: 0,
        clone: null,
        cloneCount: 0,
        logoItem: null,
        logoWidth: null,

        init() {
            this.marqueeElement = this.$refs.marquee
            this.marqueeWidth = this.marqueeElement.offsetWidth
            this.contentWidth = this.marqueeElement.scrollWidth

            this.logoItem = this.marqueeElement.querySelector('.js-logo-item')
            this.logoWidth = this.logoItem.offsetWidth
            // Wait for DOMContentLoaded event
            // Call handleResize initially to set up the marquee
            // document.addEventListener('DOMContentLoaded', () => console.log('DOC LOADED'))
            this.handleResize()
        },

        startAnimation() {
            if (animationFrameId) {
                cancelAnimationFrame(animationFrameId)
            }

            const animate = () => {
                // this.moveMarquee()
                let isAnimating = false

                function throttle(callback, delay) {
                    let timeoutId = null

                    return function () {
                        if (!isAnimating) {
                            isAnimating = true
                            callback.apply(this, arguments)

                            timeoutId = setTimeout(() => {
                                isAnimating = false
                            }, delay)
                        }
                    }
                }

                // Wrap the moveMarquee function with the throttle function
                const throttledMoveMarquee = throttle(this.moveMarquee.bind(this), throttleDelay) // Adjust the delay as needed (e.g., 16ms for approximately 60 FPS)

                // Call the throttledMoveMarquee function instead of moveMarquee in the animation loop
                throttledMoveMarquee()

                animationFrameId = requestAnimationFrame(animate)
            }

            animationFrameId = requestAnimationFrame(animate)
        },

        stopAnimation() {
            cancelAnimationFrame(animationFrameId)
        },

        moveMarquee() {
            this.posX -= this.marqueeSpeed / 100

            if (this.posX < -this.contentWidth) {
                this.posX += this.contentWidth
            }

            this.marqueeElement.style.transform = `translate3d(${this.posX}px,0,0)`
        },

        handleResize() {
            console.log('TEST MARQUEE')
            const viewportWidth = window.innerWidth
            const shouldAnimateNew =
                this.contentWidth > this.marqueeWidth || this.contentWidth > viewportWidth

            if (shouldAnimateNew && !shouldAnimate) {
                // Start animation
                this.startAnimation()
                shouldAnimate = true

                // Remove existing clones
                this.removeClones()

                // Recreate clones based on the updated marquee width
                // const logoItem = this.marqueeElement.querySelector('.js-logo-item')
                // const logoWidth = logoItem.offsetWidth
                const clonesNeeded = Math.ceil(this.marqueeWidth / this.logoWidth) + 1

                this.cloneCount = clonesNeeded

                this.clone = this.marqueeElement.cloneNode(true)
                for (let i = 0; i < clonesNeeded; i++) {
                    if (this.marqueeElement.children[i]) {
                        this.clone.appendChild(this.marqueeElement.children[i].cloneNode(true))
                    }
                }

                this.marqueeElement.appendChild(this.clone)
            } else if (!shouldAnimateNew && shouldAnimate) {
                // Stop animation
                this.stopAnimation()
                shouldAnimate = false

                // Remove existing clones
                this.removeClones()
            }
        },

        removeClones() {
            this.marqueeElement.style.transform = 'translate3d(0px,0,0)'
            this.posX = 0
            if (this.clone) {
                this.marqueeElement.removeChild(this.clone)
                this.clone = null
                this.cloneCount = 0
            }
        }
    }
}