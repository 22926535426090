const obaiaHeaderMenu = document.getElementById('js-obaia-header-menu');
const obaiaHamburgerClose = document.getElementById('js-obaia-close-responsive-menu');
const obaiaHamburgerOpen = document.getElementById('js-obaia-open-responsive-menu');
const obaiaHamburgerMenu = document.getElementById('js-obaia-responsive-menu');

function toggleMenuState() {
    obaiaHamburgerMenu.classList.toggle('!scale-x-100');
}

function checkMenuState() {
    let windowScrollTop = window.pageYOffset;
    if (windowScrollTop >= 80) {
        obaiaHeaderMenu.classList.add('is-scrolled');
    } else {
        obaiaHeaderMenu.classList.remove('is-scrolled');
    }
}

window.addEventListener('load', (e) => {
    obaiaHamburgerOpen.addEventListener('click', function () {
        toggleMenuState();
        // obaiaHamburgerMenu.classList.toggle('scale-100');
    }, {passive: true});
    obaiaHamburgerClose.addEventListener('click', function () {
        toggleMenuState();
        // obaiaHamburgerMenu.classList.toggle('scale-100');
    }, {passive: true});
    
    window.addEventListener('load', checkMenuState, {passive: true});
    window.addEventListener('scroll', checkMenuState, {passive: true});
});